@tailwind base;
@tailwind components;
@tailwind utilities;

html {
   font-family: "Helvetica Neue", "Helvetica", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
   margin: 0;
   padding: 0;
   height: 100%;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

.-webkit-sticky {
  position: sticky;
  position: -webkit-sticky;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
